<template>
  <div>
    <h2 class="h4">Add Signal Promotion Code</h2>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Code</label>
            <input type="text" placeholder="Code" v-model="code.code" class="form-control" required>
          </div>
          <div class="form-group">
            <label>Months to apply</label>
            <select placeholder="Months" class="form-control" v-model="code.months" required>
              <option value="1">1 Month</option>
              <option value="3">3 Months</option>
              <option value="6">6 Months</option>
              <option value="12">12 Months</option>
            </select>
          </div>
          <div class="form-group">
            <label>How many times can it be used?</label>
            <input type="text" placeholder="Usage limit" v-model="code.left" class="form-control" required>
          </div>
          <div class="form-group">
            <label>Expiry Date</label>
            <datepicker v-model="code.expiry" input-class="form-control" :required="true" placeholder="Expiry Date"></datepicker>
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: {}
    }
  },

  methods: {
    submit() {
      this.$axios.post('/api/v1/admin/promotions', this.code).then(() => {
        this.$toasted.show('Discount code has been added', { duration: 10000 })
        this.$router.push({ name: 'admin.promotions.index' })
      })
    }
  }
}
</script>